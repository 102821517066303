<template>
  <div
    v-if="alertOpen"
    class="absolute top-0 z-50 w-full px-6 py-4 text-white border-0 "
  >
    <slot></slot>
    <button
      class="absolute top-4 right-0 mt-4 mr-6 text-2xl font-semibold leading-none bg-transparent outline-none focus:outline-none"
      v-on:click="closeAlert()"
    >
      <span>×</span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertOpen: true
    };
  },
  methods: {
    closeAlert: function() {
      this.alertOpen = false;
    }
  }
};
</script>
