<template>
  <user-index class="flex items-center justify-center h-full">
    <div
      class="flex items-center justify-center w-full h-screen overflow-y-auto"
    >
      <div
        class="flex flex-col h-full p-2 place-content-between x-sm:w-full sm:w-2/3 lg:w-1/3"
      >
        <div class="flex justify-center p-4">
          <img
            class="object-scale-down w-full x-sm:h-48 md:h-72 lg:h-72 xl:h-80 2xl:h-96"
            src="../../assets/img/LoginLogo.png"
          />
        </div>
        <div class="p-1">
          <div class="flex justify-center">
            <div>
              <button
                class="flex items-center justify-center w-20 h-20 p-2 rounded-full bg-qgo-primary-bg focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="36"
                  height="36"
                  fill="white"
                >
                  <g>
                    <path
                      d="M15,159.886c8.284,0,15-6.716,15-15V60.031C30,43.472,43.472,30,60.032,30H144.5c8.284,0,15-6.716,15-15s-6.716-15-15-15   H60.032C26.93,0,0,26.93,0,60.031v84.854C0,153.17,6.716,159.886,15,159.886z"
                    />
                    <path
                      d="M144.5,482H60.032C43.472,482,30,468.528,30,451.969v-84.575c0-8.284-6.716-15-15-15s-15,6.716-15,15v84.575   C0,485.07,26.93,512,60.032,512H144.5c8.284,0,15-6.716,15-15S152.784,482,144.5,482z"
                    />
                    <path
                      d="M497,352.394c-8.284,0-15,6.716-15,15v84.575c0,16.56-13.472,30.031-30.032,30.031H367.56c-8.284,0-15,6.716-15,15   s6.716,15,15,15h84.408C485.07,512,512,485.07,512,451.969v-84.575C512,359.109,505.284,352.394,497,352.394z"
                    />
                    <path
                      d="M451.968,0H367.56c-8.284,0-15,6.716-15,15s6.716,15,15,15h84.408C468.528,30,482,43.472,482,60.031v84.854   c0,8.284,6.716,15,15,15s15-6.716,15-15V60.031C512,26.93,485.07,0,451.968,0z"
                    />
                    <path
                      d="M59.367,78.574v142.58c0,10.273,8.358,18.632,18.632,18.632h142.58c10.273,0,18.632-8.358,18.632-18.632V78.574   c0-10.273-8.358-18.632-18.632-18.632H77.999C67.725,59.942,59.367,68.301,59.367,78.574z M89.367,89.942H209.21v119.844H89.367   V89.942z"
                    />
                    <path
                      d="M290.854,239.786h142.58c10.273,0,18.632-8.358,18.632-18.632V78.574c0-10.273-8.358-18.632-18.632-18.632h-142.58   c-10.273,0-18.632,8.358-18.632,18.632v142.58C272.222,231.428,280.581,239.786,290.854,239.786z M302.222,89.942h119.844v119.844   H302.222V89.942z"
                    />
                    <path
                      d="M220.862,272.307H78.282c-10.273,0-18.632,8.358-18.632,18.632v142.58c0,10.273,8.358,18.632,18.632,18.632h142.58   c10.273,0,18.632-8.358,18.632-18.632v-142.58C239.494,280.665,231.136,272.307,220.862,272.307z M209.494,422.15H89.65V302.307   h119.844V422.15z"
                    />
                    <path
                      d="M369.244,437.15c0,8.284,6.716,15,15,15h49.474c10.273,0,18.632-8.358,18.632-18.632v-46.539c0-8.284-6.716-15-15-15   s-15,6.716-15,15v35.171h-38.106C375.959,422.15,369.244,428.866,369.244,437.15z"
                    />
                    <path
                      d="M172.738,108.702h-46.898c-9.767,0-17.713,7.946-17.713,17.713v46.898c0,9.768,7.946,17.714,17.713,17.714h46.898   c9.768,0,17.714-7.946,17.714-17.714v-46.898C190.452,116.648,182.505,108.702,172.738,108.702z M160.452,161.027h-22.326v-22.325   h22.326V161.027z"
                    />
                    <path
                      d="M346.138,336.979c0-8.284-6.716-15-15-15h-36.079c-10.433,0-18.921,8.488-18.921,18.922v46.078c0,8.284,6.716,15,15,15   s15-6.716,15-15v-35h25C339.422,351.979,346.138,345.264,346.138,336.979z"
                    />
                    <path
                      d="M287.222,305.938h91.249v66.041h-38.125c-8.284,0-15,6.716-15,15s6.716,15,15,15h52.032   c8.874,0,16.092-7.219,16.092-16.092V294.86c0-10.434-8.488-18.922-18.922-18.922H287.222c-8.284,0-15,6.716-15,15   S278.938,305.938,287.222,305.938z"
                    />
                    <path
                      d="M437.066,351.979c8.284,0,15-6.716,15-15v-46.041c0-8.284-6.716-15-15-15s-15,6.716-15,15v46.041   C422.066,345.264,428.782,351.979,437.066,351.979z"
                    />
                    <path
                      d="M359.138,437.15c0-8.284-6.716-15-15-15h-56.916c-8.284,0-15,6.716-15,15s6.716,15,15,15h56.916   C352.422,452.15,359.138,445.435,359.138,437.15z"
                    />
                    <path
                      d="M385.593,108.702h-46.898c-9.767,0-17.713,7.946-17.713,17.713v46.898c0,9.768,7.946,17.714,17.713,17.714h46.898   c9.768,0,17.714-7.946,17.714-17.714v-46.898C403.307,116.648,395.361,108.702,385.593,108.702z M373.307,161.027h-22.326v-22.325   h22.326V161.027z"
                    />
                    <path
                      d="M125.839,403.392h46.898c9.768,0,17.714-7.946,17.714-17.714v-46.898c0-9.768-7.946-17.714-17.714-17.714h-46.898   c-9.767,0-17.713,7.946-17.713,17.714v46.898C108.126,395.445,116.072,403.392,125.839,403.392z M138.126,351.065h22.326v22.326   h-22.326V351.065z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="flex flex-col w-full p-4">
            <div>
              <p class="font-bold text-white">SELECT A NEW TICKET</p>
            </div>
            <div class="mt-8 text-white">
              <p class="text-xs text-white-">OR SIGN IN FIRST:</p>
            </div>
            <signin-options></signin-options>
          </div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<script>
import { defineComponent } from "vue";
import UserIndex from "@/views/User/Index";
import SigninOptions from "@/views/User/Session/SigninOptions";

export default defineComponent({
  setup() {
    return {};
  },
  data() {
    return {};
  },
  components: {
    UserIndex,
    SigninOptions
  },
  methods: {}
});
</script>
