<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <user-index class="flex flex-col w-full min-h-screen">
    <header>
      <user-header>
        <div class="flex flex-row h-full">
          <div class="w-1/3"></div>
          <div class="flex items-end justify-center w-1/3">
            <svg
              class="w-20 h-10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 83 22"
            >
              <g
                id="Group_13"
                data-name="Group 13"
                transform="translate(0 0.5)"
              >
                <g id="Group_8" data-name="Group 8" transform="translate(0 0)">
                  <g id="Group_7" data-name="Group 7">
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M366,416.651l2.511-2.7-2.933-2.8a16.528,16.528,0,0,1-2.616,2.828l-2.511-2.643c2.061-1.031,3.7-2.3,3.7-4.783,0-3.409-3.066-5.418-6.237-5.418-3.2,0-6.184,2.035-6.184,5.47a5.442,5.442,0,0,0,1.427,3.462l.423.581-.475.212c-2.511,1.11-4.281,2.564-4.281,5.55,0,3.647,2.96,5.708,6.4,5.708a12.575,12.575,0,0,0,6.818-2.378l2.248,2.38,6.424-.051-4.946-5.184Zm-9.566.819a2.053,2.053,0,0,1-2.246-1.823c0-1.11,1.031-1.85,1.876-2.379l3.145,3.383A5.658,5.658,0,0,1,356.433,417.47Zm1.771-8.536c-.634-.713-1.559-1.665-1.559-2.642a1.6,1.6,0,0,1,1.665-1.639,1.751,1.751,0,0,1,1.744,1.85A3.114,3.114,0,0,1,358.2,408.934Z"
                      transform="translate(-325.64 -401.138)"
                      fill="#fff"
                    />
                    <path
                      id="Path_7"
                      data-name="Path 7"
                      d="M269.988,411.5c0-6.2-4.609-10.231-10.56-10.231-5.5,0-10.56,4.052-10.56,9.825a10.153,10.153,0,0,0,10.56,10.534,9.868,9.868,0,0,0,5.242-1.241l1.519,1.8,5.273.007-3.931-3.982A10.392,10.392,0,0,0,269.988,411.5Zm-6.052,2.837-1.342-1.393-5.253.014,3.632,3.607a4.556,4.556,0,0,1-1.519.254,5.492,5.492,0,0,1-5.419-5.7,5.361,5.361,0,0,1,5.419-5.039,5.487,5.487,0,0,1,5.369,5.445A4.931,4.931,0,0,1,263.935,414.333Z"
                      transform="translate(-248.868 -401.236)"
                      fill="#fff"
                    />
                  </g>
                </g>
                <g
                  id="Group_11"
                  data-name="Group 11"
                  transform="translate(44.372 0)"
                >
                  <path
                    id="Union_1"
                    data-name="Union 1"
                    d="M0,10.477A10.628,10.628,0,0,1,10.774,0a10.924,10.924,0,0,1,6.3,1.979A13.437,13.437,0,0,0,14.831,5.9a5.883,5.883,0,0,0-4.058-1.634,6.115,6.115,0,0,0-6.016,6.209,6.115,6.115,0,0,0,6.016,6.209,5.886,5.886,0,0,0,4.056-1.628,6.237,6.237,0,0,0,1.713-2.82H9.619l1.031-1.863L9.619,8.512h17.36V6.838L29.1,8.607l2.125,1.768L29.1,12.143l-2.125,1.768V12.238H21.393a10.446,10.446,0,0,1-4.329,6.743,10.925,10.925,0,0,1-6.29,1.972A10.628,10.628,0,0,1,0,10.477Z"
                    transform="translate(0 0)"
                    fill="#009a74"
                    stroke="rgba(0,0,0,0)"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <g
                    id="Group_10"
                    data-name="Group 10"
                    transform="translate(17.32)"
                  >
                    <path
                      id="Path_9"
                      data-name="Path 9"
                      d="M525.446,401.138a10.7,10.7,0,0,0-10.582,8.512h4.875a6.038,6.038,0,0,1,5.706-4.244,6.212,6.212,0,0,1,0,12.418,5.886,5.886,0,0,1-4.058-1.63,13.343,13.343,0,0,1-2.239,3.921,10.924,10.924,0,0,0,6.3,1.977,10.481,10.481,0,1,0,0-20.953Z"
                      transform="translate(-514.864 -401.138)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="flex items-end justify-end w-1/3">
            <button
              @click="logout()"
              class="focus:outline-none text-white pr-4"
            >
              <svg
                class="w-5 h-5 m-2 logout"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#009a73"
              >
                <path
                  d="m23.1 10.208-1.296-.13c-.087-.449-.205-.89-.35-1.316l1.056-.759c.418-.301.54-.865.282-1.312l-.798-1.381c-.257-.446-.806-.623-1.277-.412l-1.182.534c-.299-.343-.623-.667-.966-.966l.534-1.183c.211-.47.034-1.019-.412-1.277l-1.381-.797c-.448-.258-1.011-.136-1.312.282l-.759 1.056c-.426-.146-.867-.263-1.316-.35l-.13-1.296c-.052-.514-.48-.901-.996-.901h-1.595c-.516 0-.944.387-.995.9l-.13 1.296c-.449.087-.89.205-1.316.35l-.759-1.055c-.3-.418-.864-.54-1.312-.282l-1.38.797c-.447.258-.624.807-.412 1.278l.534 1.182c-.344.299-.667.622-.966.966l-1.183-.535c-.47-.211-1.019-.034-1.277.413l-.797 1.38c-.258.448-.136 1.012.282 1.312l1.056.759c-.146.426-.263.867-.35 1.316l-1.296.13c-.514.052-.901.48-.901.996v1.595c0 .516.387.944.9.995l1.296.13c.087.449.205.89.35 1.316l-1.056.759c-.418.301-.54.865-.282 1.312l.797 1.381c.258.447.808.625 1.277.412l1.182-.534c.299.343.623.667.966.966l-.534 1.183c-.211.47-.034 1.019.412 1.277l1.381.797c.448.259 1.012.137 1.312-.282l.759-1.056c.426.146.867.263 1.316.35l.13 1.296c.053.513.481.9.997.9h1.595c.516 0 .944-.387.995-.9l.13-1.296c.449-.087.89-.205 1.316-.35l.759 1.056c.301.418.863.539 1.312.282l1.381-.797c.446-.258.624-.807.412-1.277l-.534-1.182c.343-.299.667-.623.966-.966l1.183.534c.47.211 1.02.035 1.277-.412l.797-1.381c.258-.447.136-1.011-.282-1.312l-1.056-.759c.146-.426.263-.867.35-1.316l1.296-.13c.513-.053.9-.481.9-.997v-1.595c0-.515-.387-.943-.9-.994zm-11.1-5.208c3.519 0 6.432 2.613 6.92 6h-4.632c-.387-.881-1.265-1.5-2.288-1.5-.093 0-.182.018-.273.028l-2.322-4.024c.803-.322 1.678-.504 2.595-.504zm-7 7c0-2.227 1.049-4.21 2.674-5.493l2.319 4.016c-.306.416-.493.923-.493 1.477s.187 1.061.493 1.476l-2.319 4.016c-1.625-1.283-2.674-3.265-2.674-5.492zm7 7c-.917 0-1.792-.182-2.595-.505l2.323-4.023c.09.01.179.028.272.028 1.023 0 1.901-.619 2.288-1.5h4.632c-.488 3.387-3.401 6-6.92 6z"
                /></svg
              >Logout
            </button>
          </div>
        </div>
      </user-header>
    </header>
    <main class="flex w-full bg-gray-200">
      <div class="flex flex-col justify-center w-full user-main-container">
        <div class="flex w-full h-56">
          <div
            v-if="queueData.length > 0"
            class="flex w-full h-56 px-10 overflow-x-auto overflow-y-hidden"
          >
            <div class="flex flex-col justify-center h-56 my-2">
              <p class="font-extrabold">T</p>
              <p class="font-extrabold">I</p>
              <p class="font-extrabold">C</p>
              <p class="font-extrabold">K</p>
              <p class="font-extrabold">E</p>
              <p class="font-extrabold">T</p>
              <p class="font-extrabold">S</p>
            </div>
            <div
              v-for="queue in queueData"
              :key="queue.id"
              class="flex pt-1 mx-4 my-2 h-52"
            >
              <Card
                class="cursor-pointer w-36"
                :id="`queueCard-${queue.id}`"
                @click="openExpandOrder(queue)"
                :queue="queue"
              ></Card>
            </div>
            <in-queue ref="inQueue"></in-queue>
          </div>
          <div
            v-else
            class="flex items-center justify-center w-full h-56 px-10"
          >
            <p class="text-gray-400 text-9xl">NO TICKETS YET</p>
          </div>
        </div>
        <!-- <div class="flex h-8 px-10 pt-2">
          <input
            name="vendorSearch"
            type="text"
            class="
              mt-0
              block
              w-full
              px-0.5
              py-1
              border-0 border-b-2
              text-qgo-secondary-bg
              border-gray-600
              focus:ring-0 focus:border-qgo-primary-bg
              bg-gray-200
            "
            placeholder="Look for Vendors"
            v-model="search"
          />
          <button class="focus:outline-none">
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div> -->
        <div class="flex flex-grow h-52">
          <div class="flex flex-grow w-full overflow-y-auto">
            <div
              class="
                flex flex-wrap
                w-full
                h-full
                px-10
                x-sm:justify-center
                sm:justify-center
                md:justify-center
                lg:justify-start
              "
            >
              <div
                v-for="(vendor, index) in filteredList"
                :id="`vendor-${vendor.id}`"
                :key="index"
                @click="openExpandVendor(vendor)"
                class="
                  flex flex-col
                  items-center
                  justify-center
                  h-40
                  m-4
                  transform
                  cursor-pointer
                  w-52
                  hover:scale-110
                "
              >
                <img
                  class="w-48 bg-gray-100 shadow-md h-36"
                  :src="getImgUrl(vendor.img)"
                  :alt="vendor.name + ' Logo'"
                />
                <p>{{ vendor.name }}</p>
              </div>
              <div class="flex items-center justify-center w-full h-12">
                <!-- <button
                  class="w-40 p-1 text-white rounded-md bg-qgo-secondary-bg"
                  @click="addLimit"
                >
                  Show More
                </button> -->
              </div>
              <vendor-queue ref="vendorQueue"></vendor-queue>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <user-footer>
        <!-- <button
          class="relative w-24 h-24 m-auto rounded-full -top-14 bg-qgo-primary-bg focus:outline-none"
        >
          <span
            class="relative -m-10 text-xs text-gray-400 justify-self-center -top-10"
            >ADD A NEW TICKET</span
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="42"
            height="42"
            fill="white"
            class="relative m-auto -top-3"
          >
            <g>
              <path
                d="M15,159.886c8.284,0,15-6.716,15-15V60.031C30,43.472,43.472,30,60.032,30H144.5c8.284,0,15-6.716,15-15s-6.716-15-15-15   H60.032C26.93,0,0,26.93,0,60.031v84.854C0,153.17,6.716,159.886,15,159.886z"
              />
              <path
                d="M144.5,482H60.032C43.472,482,30,468.528,30,451.969v-84.575c0-8.284-6.716-15-15-15s-15,6.716-15,15v84.575   C0,485.07,26.93,512,60.032,512H144.5c8.284,0,15-6.716,15-15S152.784,482,144.5,482z"
              />
              <path
                d="M497,352.394c-8.284,0-15,6.716-15,15v84.575c0,16.56-13.472,30.031-30.032,30.031H367.56c-8.284,0-15,6.716-15,15   s6.716,15,15,15h84.408C485.07,512,512,485.07,512,451.969v-84.575C512,359.109,505.284,352.394,497,352.394z"
              />
              <path
                d="M451.968,0H367.56c-8.284,0-15,6.716-15,15s6.716,15,15,15h84.408C468.528,30,482,43.472,482,60.031v84.854   c0,8.284,6.716,15,15,15s15-6.716,15-15V60.031C512,26.93,485.07,0,451.968,0z"
              />
              <path
                d="M59.367,78.574v142.58c0,10.273,8.358,18.632,18.632,18.632h142.58c10.273,0,18.632-8.358,18.632-18.632V78.574   c0-10.273-8.358-18.632-18.632-18.632H77.999C67.725,59.942,59.367,68.301,59.367,78.574z M89.367,89.942H209.21v119.844H89.367   V89.942z"
              />
              <path
                d="M290.854,239.786h142.58c10.273,0,18.632-8.358,18.632-18.632V78.574c0-10.273-8.358-18.632-18.632-18.632h-142.58   c-10.273,0-18.632,8.358-18.632,18.632v142.58C272.222,231.428,280.581,239.786,290.854,239.786z M302.222,89.942h119.844v119.844   H302.222V89.942z"
              />
              <path
                d="M220.862,272.307H78.282c-10.273,0-18.632,8.358-18.632,18.632v142.58c0,10.273,8.358,18.632,18.632,18.632h142.58   c10.273,0,18.632-8.358,18.632-18.632v-142.58C239.494,280.665,231.136,272.307,220.862,272.307z M209.494,422.15H89.65V302.307   h119.844V422.15z"
              />
              <path
                d="M369.244,437.15c0,8.284,6.716,15,15,15h49.474c10.273,0,18.632-8.358,18.632-18.632v-46.539c0-8.284-6.716-15-15-15   s-15,6.716-15,15v35.171h-38.106C375.959,422.15,369.244,428.866,369.244,437.15z"
              />
              <path
                d="M172.738,108.702h-46.898c-9.767,0-17.713,7.946-17.713,17.713v46.898c0,9.768,7.946,17.714,17.713,17.714h46.898   c9.768,0,17.714-7.946,17.714-17.714v-46.898C190.452,116.648,182.505,108.702,172.738,108.702z M160.452,161.027h-22.326v-22.325   h22.326V161.027z"
              />
              <path
                d="M346.138,336.979c0-8.284-6.716-15-15-15h-36.079c-10.433,0-18.921,8.488-18.921,18.922v46.078c0,8.284,6.716,15,15,15   s15-6.716,15-15v-35h25C339.422,351.979,346.138,345.264,346.138,336.979z"
              />
              <path
                d="M287.222,305.938h91.249v66.041h-38.125c-8.284,0-15,6.716-15,15s6.716,15,15,15h52.032   c8.874,0,16.092-7.219,16.092-16.092V294.86c0-10.434-8.488-18.922-18.922-18.922H287.222c-8.284,0-15,6.716-15,15   S278.938,305.938,287.222,305.938z"
              />
              <path
                d="M437.066,351.979c8.284,0,15-6.716,15-15v-46.041c0-8.284-6.716-15-15-15s-15,6.716-15,15v46.041   C422.066,345.264,428.782,351.979,437.066,351.979z"
              />
              <path
                d="M359.138,437.15c0-8.284-6.716-15-15-15h-56.916c-8.284,0-15,6.716-15,15s6.716,15,15,15h56.916   C352.422,452.15,359.138,445.435,359.138,437.15z"
              />
              <path
                d="M385.593,108.702h-46.898c-9.767,0-17.713,7.946-17.713,17.713v46.898c0,9.768,7.946,17.714,17.713,17.714h46.898   c9.768,0,17.714-7.946,17.714-17.714v-46.898C403.307,116.648,395.361,108.702,385.593,108.702z M373.307,161.027h-22.326v-22.325   h22.326V161.027z"
              />
              <path
                d="M125.839,403.392h46.898c9.768,0,17.714-7.946,17.714-17.714v-46.898c0-9.768-7.946-17.714-17.714-17.714h-46.898   c-9.767,0-17.713,7.946-17.713,17.714v46.898C108.126,395.445,116.072,403.392,125.839,403.392z M138.126,351.065h22.326v22.326   h-22.326V351.065z"
              />
            </g>
          </svg>
        </button> -->
      </user-footer>
    </footer>
  </user-index>
</template>

<script>
import { defineComponent } from "vue";
import UserIndex from "@/views/User/Index";
import Card from "@/views/User/Queue/QueueCards.vue";
import UserHeader from "@/components/userpages/Header.vue";
import UserFooter from "@/components/userpages/Footer.vue";
import InQueue from "@/views/User/Queue/InQueue.vue";
import VendorQueue from "@/views/User/Queue/Vendor.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  data() {
    return {
      isLoading: false,
      queueData: [],
      dataInterval: null,
      vendorData: [],
      // vendorData: [
      //   {
      //     id: 1,
      //     name: "Vendor1",
      //     img: "img/sample-logo1.jpg",
      //   },
      //   {
      //     id: 2,
      //     name: "Vendor2",
      //     img: "img/sample-logo2.jpg",
      //   },
      //   {
      //     id: 3,
      //     name: "Vendor3",
      //     img: "img/sample-logo3.jpg",
      //   },
      //   {
      //     id: 4,
      //     name: "Vendor4",
      //     img: "img/sample-logo4.jpg",
      //   },
      //   {
      //     id: 5,
      //     name: "Vendor5",
      //     img: "img/sample-logo7.jpg",
      //   },
      //   {
      //     id: 6,
      //     name: "Vendor6",
      //     img: "img/sample-logo6.png",
      //   },
      //   {
      //     id: 7,
      //     name: "Vendor7",
      //     img: "img/sample-logo1.jpg",
      //   },
      //   {
      //     id: 8,
      //     name: "Vendor8",
      //     img: "img/sample-logo2.jpg",
      //   },
      //   {
      //     id: 9,
      //     name: "Vendor9",
      //     img: "img/sample-logo3.jpg",
      //   },
      //   {
      //     id: 10,
      //     name: "Vendor10",
      //     img: "img/sample-logo4.jpg",
      //   },
      //   {
      //     id: 11,
      //     name: "Vendor11",
      //     img: "img/sample-logo7.jpg",
      //   },
      //   {
      //     id: 12,
      //     name: "Vendor12",
      //     img: "img/sample-logo6.png",
      //   },
      // ],
      search: "",
      limit: 5,
    };
  },
  setup() {
    return {};
  },
  components: {
    UserIndex,
    Card,
    UserHeader,
    UserFooter,
    InQueue,
    VendorQueue,
    Loading,
  },
  computed: {
    filteredList() {
      return this.limit
        ? this.vendorData
            .filter((vendor) => {
              return vendor.name
                .toLowerCase()
                .includes(this.search.toLowerCase());
            })
            .slice(0, this.limit)
        : this.vendorData.filter((vendor) => {
            return vendor.name
              .toLowerCase()
              .includes(this.search.toLowerCase());
          });
    },
  },
  created() {
    this.getUserOrders();
    this.dataInterval = setInterval(() => {
      this.getUserOrders();
    }, 3000);
  },
  unmounted() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }
  },
  methods: {
    logout() {
      this.isLoading = true;
      localStorage.clear();
      this.$http
        .post("auth/logout", {})
        .then((response) => {
          this.$router.push({ name: "User" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.$router.push({ name: "User" });
          this.isLoading = false;
        });
    },
    getUserOrders() {
      this.isLoading = true;
      this.$http
        .get("api/v1/order/get")
        .then((response) => {
          this.isLoading = false;
          if (response && response.data && response.data.status) {
            this.queueData = response.data.qrdata;
          } else {
            this.queueData = [];
            //no orders found
          }
        })
        .catch((error) => {
          // this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    openExpandOrder(queue) {
      this.$refs.inQueue.showInQueue(queue);
    },
    openExpandVendor(vendor) {
      this.$refs.vendorQueue.showVendor(vendor);
    },
    addLimit() {
      this.limit = this.limit + 5;
    },
    getImgUrl(pic) {
      return require("../../assets/" + pic);
    },
  },
});
</script>
<style scoped>
.logout {
  display: inline;
}
</style>
