<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <user-index class="flex items-center justify-center h-full">
    <div
      class="flex items-center justify-center w-full h-screen overflow-y-auto"
    >
      <div
        class="
          flex flex-col
          h-full
          p-2
          place-content-between
          x-sm:w-full
          sm:w-2/3
          lg:w-1/3
          u-signup
        "
      >
        <div class="flex justify-center p-4">
          <div class="flex justify-between w-full">
            <transition name="slide-logo" mode="in-out">
              <img
                v-show="logo_transition"
                class="object-scale-down h-20"
                src="../../../assets/img/LoginLogo.png"
              />
            </transition>
            <div class="flex flex-col items-end justify-end">
              <p
                class="text-2xl font-bold text-qgo-primary-bg justify-self-end"
              >
                SIGN UP
              </p>
              <p class="text-xl font-bold text-white justify-self-end">
                WELCOME
              </p>
            </div>
          </div>
        </div>
        <div class="p-1 mx-4">
          <div class="flex flex-col justify-center w-full">
            <input
              name="signupUserFullname"
              type="text"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b-2
                text-white
                border-gray-600
                focus:ring-0 focus:border-qgo-primary-bg
                bg-qgo-secondary-bg
              "
              placeholder="Full Name"
              @blur="onBlur('name')"
              v-model="input.name"
              @input="nameVerification"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.name"
            >
              {{ errors.name }}
            </p>
            <vue-tel-input
              @blur="onBlur('phn')"
              @input="onPhnInput"
              v-model="input.phone_number"
              mode="international"
            ></vue-tel-input>
            <!-- <input
              name="signupUserNumber"
              type="tel"
              pattern="^[0-9]{10}$"
              class="mt-0 block w-full px-0.5 border-0 border-b-2 text-white border-gray-600 focus:ring-0 focus:border-qgo-primary-bg bg-qgo-secondary-bg"
              placeholder="Mobile Number"
              v-model="input.phone_number"
              @blur="onBlur('phn')"
              @input="phoneNumberVerification"
            /> -->
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.phone_number"
            >
              {{ errors.phone_number }}
            </p>
            <input
              name="signupUserEmail"
              type="email"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b-2
                text-white
                border-gray-600
                focus:ring-0 focus:border-qgo-primary-bg
                bg-qgo-secondary-bg
              "
              placeholder="Email"
              @blur="onBlur('email')"
              v-model="input.email"
              @input="emailVerification"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.email"
            >
              {{ errors.email }}
            </p>
            <input
              name="signupUserPassword"
              type="password"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b-2
                text-white
                border-gray-600
                focus:ring-0 focus:border-qgo-primary-bg
                bg-qgo-secondary-bg
              "
              placeholder="Password"
              @input="passwordVerification"
              @blur="onBlur('password')"
              v-model="input.password"
              v-on:keyup.enter="signUp"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.password"
            >
              {{ errors.password }}
            </p>
            <input
              name="signupUserConfirmPassword"
              type="password"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b-2
                text-white
                border-gray-600
                focus:ring-0 focus:border-qgo-primary-bg
                bg-qgo-secondary-bg
              "
              placeholder="Confirm Password"
              @input="cpasswordVerification"
              @blur="onBlur('confirm_password')"
              v-model="input.confirm_password"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.confirm_password"
            >
              {{ errors.confirm_password }}
            </p>
          </div>
        </div>
        <div class="p-1">
          <div class="flex flex-col w-full mb-10">
            <button
              class="
                p-2
                mx-4
                my-1
                text-white
                rounded-md
                bg-qgo-primary-bg
                focus:outline-none
              "
              @click="signUp"
            >
              Create Account
            </button>
            <router-link
              class="
                p-2
                mx-4
                my-1
                text-black
                bg-white
                rounded-md
                focus:outline-none
              "
              to="/user"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<script>
import UserIndex from "@/views/User/Index";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      phnData: {},
      logo_transition: false,
      blurStatus: {
        email: "",
        password: "",
        confirm_password: "",
        phone_number: "",
        name: ""
      },
      input: {
        email: "",
        password: "",
        confirm_password: "",
        contact_name: "",
        abn: "",
        phone_number: "",
        name: "",
        country_code: ""
      },
      errors: {
        email: "",
        password: "",
        confirm_password: "",
        contact_name: "",
        abn: "",
        phone_number: "",
        name: ""
      },
      validStatus: {
        email: true,
        password: true,
        confirm_password: true,
        contact_name: true,
        abn: true,
        phone_number: true,
        name: true
      },
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  components: {
    UserIndex,
    Loading
  },
  methods: {
    signUp() {
      let arr = ["email", "password", "confirm_password", "phn", "name"];
      for (let index = 0; index < arr.length; index++) {
        this.onBlur(arr[index]);
      }
      if (
        this.validStatus.email &&
        this.validStatus.password &&
        this.validStatus.confirm_password &&
        this.validStatus.phone_number &&
        this.validStatus.name
      ) {
        this.isLoading = true;
        let reqData = {
          name: this.input.name,
          email: this.input.email,
          phone_number: this.input.phone_number,
          password_confirmation: this.input.confirm_password,
          role: 1,
          password: this.input.password,
          country_code: this.input.country_code
        };
        this.$http
          .post("/api/v1/auth/register", reqData)
          .then(response => {
            this.response = response.data;
            sessionStorage.setItem("OTP_UserID", response.data.data.user.id);
            sessionStorage.setItem("acktoken", response.data.data.access_token);
            this.$router.push({ name: "OTPVerification" });
            this.isLoading = false;
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false
            });
          })
          .catch(error => {
            console.log(error);
            console.log(error.response.data);
            let errorMessage = "";
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (
                Array.isArray(error.response.data.message) &&
                error.response.data.message.length
              ) {
                errorMessage = error.response.data.message[0];
              } else if (typeof error.response.data.message === "string") {
                errorMessage = error.response.data.message;
              } else {
                errorMessage = "Something went wrong.";
              }
            } else {
              errorMessage = "Something went wrong.";
            }
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: errorMessage,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false
            });
          });
        console.log(reqData);
      } else {
        console.log("Invalid");
        console.log(this.validStatus);
      }
    },
    emailVerification() {
      if (this.blurStatus.email) {
        if (this.input.email) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(this.input.email)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else {
            this.errors.email = "Please enter a valid email";
            this.validStatus.email = false;
          }
        } else {
          this.errors.email = "Email is required";
          this.validStatus.email = false;
        }
      } else {
        this.errors.email = "";
        this.validStatus.email = true;
      }
    },
    passwordVerification() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          if (this.input.password.length < 6) {
            this.errors.password = "The password must be at least 6 characters";
            this.validStatus.password = false;
          } else {
            this.errors.password = "";
            this.validStatus.password = true;
          }
        } else {
          this.errors.password = "Password is required";
          this.validStatus.password = false;
        }
      } else {
        this.errors.password = "";
        this.validStatus.password = true;
      }
    },
    cpasswordVerification() {
      if (this.blurStatus.confirm_password) {
        if (this.input.confirm_password) {
          if (
            this.input.password &&
            this.input.password != this.input.confirm_password
          ) {
            this.errors.confirm_password =
              "Password and confirm password does not match";
            this.validStatus.confirm_password = false;
          } else {
            this.errors.confirm_password = "";
            this.validStatus.confirm_password = true;
          }
        } else {
          this.errors.confirm_password = "Confirm password is required";
          this.validStatus.confirm_password = false;
        }
      } else {
        this.errors.confirm_password = "";
        this.validStatus.confirm_password = true;
      }
    },
    phoneNumberVerification() {
      if (this.blurStatus.phone_number) {
        if (this.input.phone_number) {
          const rgex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          if (
            rgex.test(this.input.phone_number) &&
            this.input.phone_number.length > 9 &&
            this.input.phone_number.length < 15
          ) {
            this.errors.phone_number = "";
            this.validStatus.phone_number = true;
          } else {
            this.errors.phone_number = "Invalid phone number";
            this.validStatus.phone_number = false;
          }
        } else {
          this.errors.phone_number = "Phone number is required";
          this.validStatus.phone_number = false;
        }
      } else {
        this.errors.phone_number = "";
        this.validStatus.phone_number = true;
      }
    },
    nameVerification() {
      if (this.blurStatus.name) {
        if (this.input.name) {
          if (this.input.name.length < 2 || this.input.name.length > 100) {
            this.errors.name =
              "Name should be greater than 2 and less than 100 characters";
            this.validStatus.name = false;
          } else {
            this.errors.name = "";
            this.validStatus.name = true;
          }
        } else {
          this.errors.name = "Name is required";
          this.validStatus.name = false;
        }
      } else {
        this.errors.name = "";
        this.validStatus.name = true;
      }
    },
    onPhnInput(value, data) {
      if (data && data.valid) {
        this.phnData = data;
        if (this.blurStatus.phone_number) {
          this.errors.phone_number = "";
          this.validStatus.phone_number = true;
          this.input.phone_number = data.nationalNumber;
          this.input.country_code = data.country
            ? data.country.dialCode
              ? data.country.dialCode
              : ""
            : "";
        }
      } else if (data && !data.valid) {
        this.phnData = data;
        if (this.blurStatus.phone_number) {
          this.errors.phone_number = "Invalid phone number";
          this.validStatus.phone_number = false;
          this.input.phone_number = data.nationalNumber;
          this.input.country_code = data.country
            ? data.country.dialCode
              ? data.country.dialCode
              : ""
            : "";
        }
      }
      if (this.blurStatus.phone_number && !this.input.phone_number) {
        this.errors.phone_number = "Phone number is required";
        this.validStatus.phone_number = false;
      }
    },
    onBlur(field) {
      switch (field) {
        case "email":
          this.blurStatus.email = true;
          this.emailVerification();
          break;
        case "password":
          this.blurStatus.password = true;
          this.passwordVerification();
          break;
        case "confirm_password":
          this.blurStatus.confirm_password = true;
          this.cpasswordVerification();
          break;
        case "phn":
          this.blurStatus.phone_number = true;
          // this.phoneNumberVerification();
          this.onPhnInput("", this.phnData);
          break;
        case "name":
          this.blurStatus.name = true;
          this.nameVerification();
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.logo_transition = true;
  }
};
</script>
<style lang="scss">
.u-signup {
  :focus-visible {
    outline: none;
  }
  .vti__input {
    background-color: transparent;
    border: none;
  }
  .vue-tel-input {
    border: none;
    border-bottom: 2px solid rgba(75, 85, 99, 1);
    color: #fff;
  }
  .vue-tel-input:focus-within {
    box-shadow: none;
    border-color: rgba(75, 85, 99, 1);
  }
  [type="tel"]:focus {
    outline: none;
    outline-offset: 0px;
    --tw-ring-inset: 0;
    --tw-ring-offset-width: 0px;
    --tw-ring-color: transparent;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;
    box-shadow: none;
    border-color: none;
  }
  .vti__dropdown-item {
    color: #000;
  }
  .vti__dropdown:hover {
    background-color: transparent;
  }
}
</style>
