<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <user-index class="flex flex-col items-center justify-center h-full">
    <!-- Header moved outside -->
    <!-- <AdminHeader /> -->
    <div class="flex items-center justify-between w-full px-2 py-4 md:w-2/3">
      <transition name="slide-logo" mode="in-out">
        <img
          v-show="logo_transition"
          class="object-scale-down h-20"
          src="../../../assets/img/LoginLogo.png"
        />
      </transition>
      <!-- Navigation -->
      <div class="flex items-center space-x-8">
        <div class="text-center">
          <div class="text-sm text-white">
            Dashboard
          </div>
          <div class="flex justify-center">
            <span
              class="w-1 h-1 mt-1 rounded-full"
              style="background-color: #00A388"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <div class="flex items-center justify-center w-full h-screen">
      <div class="flex flex-col h-full p-2 x-sm:w-full sm:w-2/3 lg:w-1/3">
        <div class="flex flex-col justify-center">
          <div class="p-1 mx-4">
            <div class="flex flex-col justify-center w-full">
              <div class="flex items-baseline mb-6 text-xl text-white">
                Login in your admin account
                <span class="ml-1 text-4xl leading-none" style="color: #00A388"
                  >.</span
                >
              </div>
              <div class="relative flex items-center mb-4 bg-white rounded">
                <div class="absolute left-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <input
                  name="signinUserDetail"
                  type="text"
                  class="w-full px-10 py-3 text-gray-800 rounded focus:outline-none"
                  placeholder="E-mail"
                  v-model="input.email"
                  @blur="onBlur('email')"
                  @input="emailVerification"
                />
              </div>
              <p
                class="mb-3 -mt-3 text-xs italic text-left text-red-500 errorMsg"
                v-if="!validStatus.email"
              >
                {{ errors.email }}
              </p>

              <div class="relative flex items-center mb-4 bg-white rounded">
                <div class="absolute left-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <input
                  name="signinUserPassword"
                  :type="showPassword ? 'text' : 'password'"
                  class="w-full px-10 py-3 text-gray-800 rounded focus:outline-none"
                  placeholder="Password"
                  v-model="input.password"
                  @blur="onBlur('password')"
                  @input="passwordVerification"
                  v-on:keyup.enter="signIn"
                />
                <div
                  class="absolute cursor-pointer right-3"
                  @click="togglePassword"
                >
                  <svg
                    v-if="!showPassword"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fill-rule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                      clip-rule="evenodd"
                    />
                    <path
                      d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
                    />
                  </svg>
                </div>
              </div>
              <p
                class="-mt-3 text-xs italic text-left text-red-500 errorMsg"
                v-if="!validStatus.password"
              >
                {{ errors.password }}
              </p>

              <router-link
                class="text-right text-[#707070] p-2 focus:outline-none inline-block ml-auto"
                to="/forgot-password"
              >
                Forgot password?
              </router-link>
            </div>
          </div>
          <div class="p-1">
            <div class="flex flex-col w-full mt-10">
              <button
                class="p-2 mx-4 my-1 text-white rounded-md bg-qgo-primary-bg focus:outline-none"
                @click="signIn"
              >
                LogIn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<style scoped>
@import "../../../assets/css/dashboard.css";

.bg-white {
  background-color: white;
}

input::placeholder {
  color: #999;
}

input {
  background: transparent;
}
</style>

<script>
import UserIndex from "@/views/User/Index";
import Loading from "vue-loading-overlay";
import AdminHeader from "@/components/AdminHeader.vue";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      logo_transition: false,
      input: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
      validStatus: {
        email: "",
        password: "",
      },
      blurStatus: {
        email: "",
        password: "",
      },
      showPassword: false,
    };
  },
  components: {
    UserIndex,
    Loading,
    AdminHeader,
  },
  methods: {
    signIn() {
      this.onBlur("password");
      this.onBlur("email");
      if (this.validStatus.email && this.validStatus.password) {
        this.isLoading = true;
        let req = {
          email: this.input.email,
          password: this.input.password,
        };
        this.$http
          .post("/api/v1/auth/login", req)
          .then((response) => {
            if (response.data.user.role === "administrator") {
              this.isLoading = false;
              let data = {
                id: response.data.user.id,
                email: response.data.user.email,
                isLoggedIn: true,
                access_token: response.data.access_token,
                role: response.data.user.role,
                name: response.data.user.name,
                isSocialLogin: false,
                socialType: "",
              };
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$router.push({ name: "Admin" });
            } else {
              this.$swal.fire({
                icon: "error",
                title:
                  "Sorry, You are not a Admin! Please sign up as a admin if you want to proceed.",
                timer: 8000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message === "Phone number is not verified"
            ) {
              sessionStorage.setItem("OTP_UserID", error.response.data.user.id);
              this.$router.push({ name: "OTPVerification" });
            }
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
    },
    emailVerification() {
      if (this.blurStatus.email) {
        if (this.input.email) {
          const rgex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(this.input.email)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else if (
            rgex.test(this.input.email) &&
            this.input.email.length > 9 &&
            this.input.email.length < 15
          ) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else {
            this.errors.email = "Please enter a valid email or phone number";
            this.validStatus.email = false;
          }
        } else {
          this.errors.email = "Email or Phone number is required";
          this.validStatus.email = false;
        }
      } else {
        this.errors.email = "";
        this.validStatus.email = true;
      }
    },
    passwordVerification() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          this.errors.password = "";
          this.validStatus.password = true;
        } else {
          this.errors.password = "Password is required";
          this.validStatus.password = false;
        }
      } else {
        this.errors.password = "";
        this.validStatus.password = true;
      }
    },
    onBlur(field) {
      switch (field) {
        case "email":
          this.blurStatus.email = true;
          this.emailVerification();
          break;
        case "password":
          this.blurStatus.password = true;
          this.passwordVerification();
          break;
        default:
          console.log("fgh");
          break;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  mounted() {
    this.logo_transition = true;
  },
};
</script>
