<template>
  <transition name="slide-right" mode="out-in">
    <div
      v-if="openQueue"
      class="open-queue-mask bg-qgo-secondary-bg"
      @close="openQueue = false"
    >
      <header>
        <user-header>
          <div class="flex flex-row h-full">
            <div class="flex items-end justify-start w-1/3">
              <button
                class="flex mx-1 my-2 text-qgo-primary-bg focus:outline-none"
                @click="hideInQueue"
              >
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <span class="text-sm">All Tickets</span>
              </button>
            </div>
            <div class="flex items-end justify-center w-1/3">
              <p class="m-1 text-white">My Ticket</p>
            </div>
            <div class="flex items-end justify-end w-1/3">
              <button class="m-2 focus:outline-none">
                <svg
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#009a73"
                >
                  <path
                    d="m23.1 10.208-1.296-.13c-.087-.449-.205-.89-.35-1.316l1.056-.759c.418-.301.54-.865.282-1.312l-.798-1.381c-.257-.446-.806-.623-1.277-.412l-1.182.534c-.299-.343-.623-.667-.966-.966l.534-1.183c.211-.47.034-1.019-.412-1.277l-1.381-.797c-.448-.258-1.011-.136-1.312.282l-.759 1.056c-.426-.146-.867-.263-1.316-.35l-.13-1.296c-.052-.514-.48-.901-.996-.901h-1.595c-.516 0-.944.387-.995.9l-.13 1.296c-.449.087-.89.205-1.316.35l-.759-1.055c-.3-.418-.864-.54-1.312-.282l-1.38.797c-.447.258-.624.807-.412 1.278l.534 1.182c-.344.299-.667.622-.966.966l-1.183-.535c-.47-.211-1.019-.034-1.277.413l-.797 1.38c-.258.448-.136 1.012.282 1.312l1.056.759c-.146.426-.263.867-.35 1.316l-1.296.13c-.514.052-.901.48-.901.996v1.595c0 .516.387.944.9.995l1.296.13c.087.449.205.89.35 1.316l-1.056.759c-.418.301-.54.865-.282 1.312l.797 1.381c.258.447.808.625 1.277.412l1.182-.534c.299.343.623.667.966.966l-.534 1.183c-.211.47-.034 1.019.412 1.277l1.381.797c.448.259 1.012.137 1.312-.282l.759-1.056c.426.146.867.263 1.316.35l.13 1.296c.053.513.481.9.997.9h1.595c.516 0 .944-.387.995-.9l.13-1.296c.449-.087.89-.205 1.316-.35l.759 1.056c.301.418.863.539 1.312.282l1.381-.797c.446-.258.624-.807.412-1.277l-.534-1.182c.343-.299.667-.623.966-.966l1.183.534c.47.211 1.02.035 1.277-.412l.797-1.381c.258-.447.136-1.011-.282-1.312l-1.056-.759c.146-.426.263-.867.35-1.316l1.296-.13c.513-.053.9-.481.9-.997v-1.595c0-.515-.387-.943-.9-.994zm-11.1-5.208c3.519 0 6.432 2.613 6.92 6h-4.632c-.387-.881-1.265-1.5-2.288-1.5-.093 0-.182.018-.273.028l-2.322-4.024c.803-.322 1.678-.504 2.595-.504zm-7 7c0-2.227 1.049-4.21 2.674-5.493l2.319 4.016c-.306.416-.493.923-.493 1.477s.187 1.061.493 1.476l-2.319 4.016c-1.625-1.283-2.674-3.265-2.674-5.492zm7 7c-.917 0-1.792-.182-2.595-.505l2.323-4.023c.09.01.179.028.272.028 1.023 0 1.901-.619 2.288-1.5h4.632c-.488 3.387-3.401 6-6.92 6z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </user-header>
      </header>
      <main class="flex justify-center w-full h-screen bg-qgo-secondary-bg">
        <div
          class="
            flex
            justify-center
            w-full
            overflow-y-auto
            bg-white
            user-myticket-container
            bg-opacity-20
          "
        >
          <div
            class="
              flex flex-wrap
              items-end
              justify-center
              x-sm:w-96
              sm:w-1/2
              md:w-1/2
              lg:w-1/3
              xl:w-1/4
            "
          >
            <div class="flex flex-col justify-center w-full pt-6">
              <div
                v-if="queue.order_status == 'Ready'"
                class="flex flex-col justify-center"
              >
                <p class="text-2xl font-black text-white">BELLISSIMO!</p>
                <p class="text-2xl font-black text-white">
                  YOUR Q{{ queue.order_no }}
                </p>
                <p class="text-2xl font-black text-white">IS READY!</p>
              </div>
              <div
                v-else-if="queue.order_status == 'Collected'"
                class="flex flex-col justify-center"
              >
                <p class="text-2xl font-black text-white">BELLISSIMO!</p>
                <p class="text-2xl font-black text-white">
                  YOUR Q{{ queue.order_no }}
                </p>
                <p class="text-2xl font-black text-white">IS COLLECTED!</p>
              </div>
              <div v-else class="flex flex-col justify-center">
                <p class="text-2xl font-black text-white">YOU HAVE</p>
                <p class="text-2xl font-black text-white">JOINED</p>
                <p class="text-2xl font-black text-white">
                  THE <span class="text-qgo-primary-bg">Q</span>UEUE
                </p>
              </div>
              <div
                v-if="queue.order_status == 'Ready'"
                class="w-3/5 px-2 py-3 m-auto mb-4"
              >
                <img
                  class="object-scale-down w-full"
                  src="../../../assets/img/Go-Ready.png"
                />
              </div>
              <div v-else class="w-3/5 px-2 py-3 m-auto mb-4">
                <img
                  class="object-scale-down w-full"
                  src="../../../assets/img/Go-Indeterminate.png"
                />
              </div>
            </div>
            <div class="flex w-full large-card-container">
              <div class="flex flex-col justify-center w-full h-auto px-5">
                <div
                  class="
                    flex-row
                    w-full
                    h-full
                    px-3
                    pt-3
                    bg-white
                    queue-card-shadow
                  "
                >
                  <div
                    class="h-full border-2"
                    :class="
                      queue.order_status == 'Ready'
                        ? 'border-qgo-primary-bg'
                        : 'border-green-900'
                    "
                  >
                    <div class="flex items-center justify-center h-3/5">
                      <div class="flex flex-col justify-center mb-10">
                        <p class="p-1 text-sm text-gray-400 font-extralight">
                          YOUR NUMBER IS
                        </p>
                        <p class="p-1 text-4xl font-black text-black">
                          Q{{ queue.order_no }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="flex flex-col h-2/5"
                      :class="
                        queue.order_status == 'Ready' || queue.order_status == 'Collected'
                          ? 'bg-qgo-primary-bg'
                          : 'bg-qgo-secondary-bg'
                      "
                    >
                      <span
                        class="
                          relative
                          justify-center
                          w-32
                          h-32
                          m-auto
                          bg-gray-400
                          rounded-full
                          justify-self-center
                          -top-20
                        "
                      >
                        <img
                          v-if="queue.vendor_detail && queue.vendor_detail.logo"
                          class="inline-block w-full h-full rounded-full"
                          :src="queue.vendor_detail.logo"
                        />
                        <div
                          class="
                            relative
                            flex
                            items-center
                            justify-center
                            my-2
                            -mx-8
                            text-white
                            -bottom-6
                          "
                        >
                          <p
                            v-if="queue.order_status == 'Ready'"
                            class="text-lg"
                          >
                            Ready to Collect
                          </p>
                          <p
                            v-else-if="queue.order_status == 'Collected'"
                            class="text-lg"
                          >
                            Collected
                          </p>
                          <p v-else class="text-lg">Preparing</p>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </transition>
</template>

<script>
import UserHeader from "@/components/userpages/Header.vue";

export default {
  props: {
    // queue: {
    //   type: Object
    // }
  },
  data() {
    return {
      openQueue: false,
      queue: {},
    };
  },
  components: {
    UserHeader,
  },
  methods: {
    showInQueue(queue) {
      this.openQueue = true;
      this.queue = queue;
    },
    hideInQueue() {
      this.openQueue = false;
    },
  },
};
</script>
